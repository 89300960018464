<template>
    <div>
        
    </div>
</template>

<script>
export default {
    data(){
        return {

        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
    }
}
</script>

<style>
.menuDisp_ar{
    position: fixed;
    top:25px;
    right:0px;
}
.menuDisp_en{
    position: fixed;
    top:25px;
    left:0px;
}
</style>